import Img from 'gatsby-image'
import { Text } from 'rebass'
import styled from 'styled-components'
import { Container } from '../../components/styled'

export const StyledHero = styled(Img)`
  opacity: 0.7;
  transition: all 0.8s ease;
  height: 65vh;
`

export const StyledContainer = styled(Container)`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

export const StyledReturnText = styled(Text)`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    position: fixed;
    bottom: 60px;
    right: ${props => props.theme.paddingWrapper.desktop};
  }
`
