import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Heading, Text, Flex } from 'rebass'

import Layout from '../../components/Layout'
import { BoxText } from '../../components/styled'
import { ProjectSocial } from './project.social'
import { StyledHero, StyledContainer, StyledReturnText } from './project.styled'

export const ProjectTemplate = ({ project }) => {
  const {
    coverImage,
    description,
    title,
    photos,
    category,
    year,
    location,
    slug,
  } = project

  return (
    <Layout>
      <StyledHero fluid={coverImage.fluid} backgroundColor="#eeeeee" />
      <StyledContainer pt={[4, 5]}>
        <Heading as="h1" fontSize={[4, 5]} fontWeight="300">
          {title}
        </Heading>
        <Flex
          mt={2}
          mb={[2, 3]}
          alignItems="baseline"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Text
            fontSize={2}
            fontWeight="400"
            pr={2}
            css={{ textTransform: 'uppercase' }}
          >
            {category.title} / {location} / {year}
          </Text>
          <ProjectSocial
            path={`proyectos/${slug}`}
            media={`https:${coverImage.ogimg.src}`}
            title={title}
          />
        </Flex>
        <BoxText
          fontSize={1}
          mb={3}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
        {photos &&
          photos.map(photo => (
            <Box key={photo.title} my={[2, 3]}>
              <Img fluid={photo.fluid} backgroundColor="#eeeeee" />
            </Box>
          ))}
        <StyledReturnText
          as={Link}
          fontSize={1}
          color="#666666"
          to="/proyectos"
        >
          Volver a proyectos
        </StyledReturnText>
      </StyledContainer>
    </Layout>
  )
}
