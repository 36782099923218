import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../components/SEO'
import { ProjectTemplate } from '../templates/project'
import config from '../utils/siteConfig'

const Project = ({ data }) => {
  const project = data.contentfulProyectos

  const {
    description: {
      childMarkdownRemark: { excerpt: metaDescription },
    },
    title,
  } = project

  const metaTitle = `${title} | ${config.siteTitle}`
  const image = {
    src: `https:${project.coverImage.ogimg.src}`,
    width: project.coverImage.ogimg.width,
    height: project.coverImage.ogimg.height,
  }

  return (
    <React.Fragment>
      <SEO
        isProject
        title={metaTitle}
        description={metaDescription}
        image={image}
      />
      <ProjectTemplate project={project} />
    </React.Fragment>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProyectos(slug: { eq: $slug }) {
      slug
      title
      location
      category {
        title
      }
      description {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 160)
        }
      }
      year
      coverImage {
        title
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      photos {
        title
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Project
