import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'
import { Box, Flex } from 'rebass'

import config from '../../utils/siteConfig'

export const ProjectSocial = props => {
  const styles = {
    round: true,
    size: 22,
    iconBgStyle: {
      fill: '#9d9d9d',
      cursor: 'pointer',
    },
  }

  const { path, media, title } = props

  const url = `${config.siteUrl}/${path}/`
  return (
    <Flex alignItems="center" width={['100%', 'auto']} mt={[2, 0]}>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon {...styles} />
      </FacebookShareButton>
      <Box mx={1}>
        <PinterestShareButton url={url} media={media} quote={title}>
          <PinterestIcon {...styles} />
        </PinterestShareButton>
      </Box>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon {...styles} />
      </WhatsappShareButton>
    </Flex>
  )
}
